import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, TextField, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppState } from "store";
import actions, { fetchChunkTypes } from "store/eventSounds/actions";
import Search from "components/search";
import PlacementType from "./placementType";
import MachineType from "./machineType";
import FailureType from "./failureType";
import EventType from "./eventType";
import Machine from "./machine";
import { useTranslation } from "react-i18next";
import IMuiTheme from "types/styles";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import CustomPicker from "components/customPickers";
import Checkbox from "components/checkbox";
import LabelSubcategory from "./labelSubcategory";
import MinAndMaxValue from "./minAndMaxValueFilter";
import HorizontalLine from "components/horizontalLine";

const useStyles = makeStyles((theme: IMuiTheme | Theme) => ({
  wrapper: {
    padding: theme.spacing(1.5, 0),
  },
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
  },
  picker: {
    width: "100%",
  },
  input: {
    paddingTop: theme.typography.pxToRem(10.5),
    paddingBottom: theme.typography.pxToRem(10.5),
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(1),
    paddingTop: "1rem",
  },
  calendarIcon: {
    right: 0,
  },
  outlinedDatepicker: {
    "& input": {
      border: "1px solid #ccc",
      padding: "8px 12px",
      borderRadius: "4px",
      backgroundColor: "white",
      outline: "none",
    },
  },
  pickerwrapper: {
    border: "1px solid gray",
    display: "flex",
    borderRadius: "4px",
    cursor: "pointer",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 8px",
    textAlign: "left",
    margin: 0,

    "& input": {
      border: "none",
    },
  },

  searchWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "3rem",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "1rem",
    },
  },
}));

type IProps = {
  setSearchValues: React.Dispatch<
    React.SetStateAction<{
      from: Date | null;
      to: Date | null;
      event_type: string[] | null;
      failure_type: string[] | null;
      placement_type: number[] | null;
      machine_subcategory: number[] | null;
      machine: number[] | null;
      q: string | null;
      label_subcategory: number[] | null;
      valueMin: number | null;
      valueMax: number | null;
      isEvent: boolean;
      isLabel: boolean;
    }>
  >;
  searchValues: {
    from: Date | null;
    to: Date | null;
    event_type: string[] | null;
    failure_type: string[] | null;
    placement_type: number[] | null;
    machine_subcategory: number[] | null;
    machine: number[] | null;
    q: string | null;
    label_subcategory: number[] | null;
    valueMin: number | null;
    valueMax: number | null;
    isEvent: boolean;
    isLabel: boolean;
  };
  setHideContent: React.Dispatch<React.SetStateAction<boolean>>;
  setForceUpdate?: any;
};

const SoundFilters = ({
  setSearchValues,
  searchValues,
  setHideContent,
  setForceUpdate,
}: IProps) => {
  const dispatch: any = useDispatch();
  const {
    from,
    to,
    event_type,
    failure_type,
    placement_type,
    machine_subcategory,
    machine,
    q: qValue,
    isEvent: isEventValue,
    isLabel: isLabelValue,
    label_subcategory,
    valueMin,
    valueMax,
  } = searchValues;
  const {
    placementTypeValues,
    machineTypeValues,
    failureTypeValues,
    eventTypeValues,
    machineValues,
    labelStart,
    labelEnd,
    isEvent,
    isLabel,
    labelSubcategoryValues,
    minValue,
    maxValue,
  } = useSelector((state: AppState) => state.eventSounds);
  const classes = useStyles();
  const { t } = useTranslation();
  const [q, setQ] = useState<string | null>(qValue ? qValue : "");

  const onChangeLabelStart = useCallback(
    (date: any) => {
      dispatch(actions.setLabelStart(date));
    },
    [dispatch]
  );
  const onChangeLabelEnd = useCallback(
    (date: any) => {
      dispatch(actions.setLabelEnd(date));
    },
    [dispatch]
  );
  useEffect(() => {
    dispatch(actions.setLabelStart(from));
    dispatch(actions.setLabelEnd(to));
    dispatch(actions.setEventTypeValues(event_type));
    dispatch(actions.setFailureTypeValues(failure_type));
    dispatch(actions.setMachineValues(machine));
    dispatch(actions.setMachineTypeValues(machine_subcategory));
    dispatch(actions.setPlacementTypeValues(placement_type));
    dispatch(actions.setLabelSubcategoryValues(label_subcategory));
    dispatch(actions.setMinValue(valueMin));
    dispatch(actions.setMaxValue(valueMax));
    dispatch(actions.setIsEvent(isEventValue));
    dispatch(actions.setIsLabel(isLabelValue));
  }, [
    dispatch,
    event_type,
    failure_type,
    from,
    machine,
    machine_subcategory,
    placement_type,
    to,
    label_subcategory,
    valueMin,
    valueMax,
    isEventValue,
    isLabelValue,
  ]);

  useEffect(() => {
    dispatch(fetchChunkTypes());
  }, [dispatch]);

  const onSearch = useCallback(() => {
    setSearchValues({
      from: labelStart,
      to: labelEnd,
      event_type: eventTypeValues,
      failure_type: failureTypeValues,
      placement_type: placementTypeValues,
      machine_subcategory: machineTypeValues,
      machine: machineValues,
      q,
      label_subcategory: labelSubcategoryValues,
      valueMin: minValue,
      valueMax: maxValue,
      isEvent,
      isLabel,
    });
    dispatch(actions.setPage(0));
    dispatch(actions.fetchLabelSoundsSuccess(null));
    dispatch(actions.fetchEventSoundsSuccess(null));
    setForceUpdate((prev: any) => prev + 1);
    dispatch(actions.setIsSearchedClicked(true));
    setHideContent(true);
  }, [
    eventTypeValues,
    failureTypeValues,
    labelEnd,
    labelStart,
    machineTypeValues,
    placementTypeValues,
    machineValues,
    q,
    setSearchValues,
    dispatch,
    labelSubcategoryValues,
    minValue,
    maxValue,
    isEvent,
    isLabel,
  ]);

  const onClearSearch = useCallback(() => {
    setSearchValues({
      from: null,
      to: null,
      event_type: null,
      failure_type: null,
      placement_type: null,
      machine_subcategory: null,
      machine: null,
      q: null,
      label_subcategory: null,
      valueMin: null,
      valueMax: null,
      isEvent: true,
      isLabel: false,
    });
    dispatch(actions.setPage(0));

    dispatch(actions.setEventTypeValues(null));
    dispatch(actions.setFailureTypeValues(null));
    dispatch(actions.setMachineValues(null));
    dispatch(actions.setMachineTypeValues(null));
    dispatch(actions.setPlacementTypeValues(null));

    dispatch(actions.setLabelStart(null));
    dispatch(actions.setLabelEnd(null));
    setQ("");
    dispatch(actions.setLabelSubcategoryValues(null));
    dispatch(actions.setMinValue(null));
    dispatch(actions.setMaxValue(null));

    dispatch(actions.setIsEvent(true));
    dispatch(actions.setIsLabel(false));
    dispatch(actions.setIsSearchedClicked(false));
  }, [dispatch, setSearchValues]);

  const onQSearch = useCallback((event: any) => {
    setQ(event);
  }, []);

  return (
    <Box>
      <div className={classes.wrapper}>
        <Grid
          paddingBottom={1}
          container
          spacing={1}
          className={classes.container}
        >
          <Grid item xs={12} sm={3} lg={2}>
            <MachineType />
          </Grid>
          <Grid item xs={12} sm={3} lg={2}>
            <Machine />
          </Grid>
          <Grid item xs={12} sm={3} lg={2}>
            <PlacementType />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            lg={2}
            sx={{
              "& .MuiInputBase-root": {
                paddingRight: "15px",
              },
            }}
          >
            <CustomPicker
              value={labelStart ? labelStart : null}
              onChange={onChangeLabelStart}
              label={t("eventSounds.dataSelection.filter.startDate")}
              showTimeSelect={false}
              dateFormat="yyyy/MM/dd"
              id="testDatePicker-start"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            lg={2}
            sx={{
              "& .MuiInputBase-root": {
                paddingRight: "15px",
              },
            }}
          >
            <CustomPicker
              value={labelEnd ?? null}
              onChange={onChangeLabelEnd}
              label={t("eventSounds.dataSelection.filter.endDate")}
              showTimeSelect={false}
              dateFormat="yyyy/MM/dd"
              id="testDatePicker-end"
              minDate={dayjs(labelStart).toDate() || undefined}
            />
          </Grid>
          <Grid item xs={12} sm={3} lg={2}>
            <Search
              value={q as string}
              onSearch={onQSearch}
              placeholder={t("search.searchEventSound")}
              isAutoSearch={true}
              hideIcon={true}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: { xs: "none", sm: "block" },

            "& > div": {
              marginLeft: 0,
              marginRight: 0,
            },
          }}
        >
          <HorizontalLine />
        </Box>
        <Box paddingTop={1} className={classes.searchWrapper}>
          <Grid
            width="100%"
            container
            spacing={1}
            className={classes.container}
            flexBasis="40%"
          >
            <Grid item xs={12} sm={9} lg={4}>
              <Checkbox
                checked={isEvent}
                label={t("eventSounds.dataSelection.filter.events")}
                onChange={(e) => {
                  dispatch(actions.setIsEvent(!isEvent));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={9} lg={4}>
              <EventType disabled={!isEvent} />
            </Grid>
            <Grid item xs={12} sm={9} lg={4}>
              <FailureType disabled={!isEvent} />
            </Grid>
          </Grid>
          <Grid
            width="100%"
            container
            spacing={1}
            className={classes.container}
            flexBasis="60%"
          >
            <Grid item xs={12} sm={9} lg={3}>
              <Checkbox
                checked={isLabel}
                label={t("eventSounds.dataSelection.filter.labels.search")}
                onChange={(e) => {
                  dispatch(actions.setIsLabel(!isLabel));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={9} lg={3}>
              <LabelSubcategory disabled={!isLabel} />
            </Grid>
            <MinAndMaxValue disabled={!isLabel} />
          </Grid>
        </Box>
        <Box className={classes.buttons}>
          <Button onClick={onClearSearch} variant="contained">
            {t("filter.clear")}
          </Button>
          <Button
            disabled={!isLabel && !isEvent}
            onClick={onSearch}
            variant="contained"
          >
            {t("eventSounds.dataSelection.filter.searchButton")}
          </Button>
        </Box>
      </div>
    </Box>
  );
};

export default SoundFilters;
